.main-app {
    text-align: center;
    margin: 100px auto 0px auto;
    /*max-width: 640px;*/
    padding: 0px 8px 0px 8px;
    width: auto;
}

.cta-button {
    padding: 15px;
    margin: 4px;
    border: 4px solid black;
    border-radius: 20px;
    min-width: 160px;
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.connect-wallet-button {
    background: #FFE179;
}

.mint-nft-button {
    background: #ad2d49;
}

    .mint-nft-button:hover {
        background: #891135;
    }

.plus-minus-button {
    padding: 0px 0px 0px 0px;
    background: #FFE179;
    min-width: 24px;
    width: 32px;
    height: 32px;
    color: black;
}

.plus-minus-button:hover {
    background: #D7B011;
}

.log {
    border: 1px solid white;
    color: rgb(160,160,160);
    background-color: #444;
}

a {
    color: white;
}




