

html {
    background-color: RGB(255,180,190);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    
}

body {
    width: 100%;
    margin: 0px;
    color: black);
    font-family: 'Pacifico', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

    body a {
        color: blue;
    }

        body a:hover {
            color: #891135;
        }

h1 {
    font-size: 75px;
    color: black;
}

h2 {
    margin: 8px;
    font-size: 30px;
}

.h2 {
    font-size: 48px;
}

table {

    width: 100%;

}

tr {
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    height: auto;
}

.smol {
    max-height: 64px;
    height: auto;
}

    .smol img {
        height: auto;
        width: auto;
        max-height: 64px;
        max-width: 64px;
    }

.header {
    color: rgb(212,40,20);
}

.head2 {
    color: white;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.roundbox {
    width: 100%;
    border-radius: 25px;
    border: 1px solid white;
}

.navbar {
    overflow: hidden;
    background-color: #333;
    width: 100%;
}

.floater {
    float: right;
}

.navbar a {
    float: left;
    font-size: 20px;
    color: white;
    text-align: right;
    padding: 14px 16px;
    text-decoration: none;
}



.menu {
    width: 10%;
    margin-right: 8px;
    text-align: right;
    float: right;
    /*overflow: hidden;*/
}

.main {
    margin: 8px auto 8px auto;
    width: 100%;
    max-width: 1024px;
    text-align: center;
    font-size: 20px;
}

.dropdown {
    float: left;
    overflow: hidden;
}

    .dropdown .dropbtn {
        font-size: 20px;
        border: none;
        outline: none;
        color: white;
        padding: 14px 16px;
        background-color: inherit;
        font-family: inherit;
        margin: 0;
        cursor: pointer;
    }

    .navbar a:hover, .dropdown:hover .dropbtn {
        background-color: #111;
    }

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 0;
    float: left;
}

    .dropdown-content a {
        float: none;
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
    }

        .dropdown-content a:hover {
            background-color: #ddd;
        }

.dropdown a:hover {
    background-color: #ddd;
}

.show {
    display: block;
}

#log {
    border: 4px solid black;
    border-radius: 20px;
    color: black;
    background-color: white;
}

#info {
    border: 4px solid black;
    border-radius: 20px;
    color: black;
    background-color: white;
}

#friend {
    background-color: rgb(40,40,40);
}

#gif {
    max-width: 256px;
    height: auto;
}

a:target {
    background-color: yellow;
    color: blue;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
    text-align: justify;
    margin: 0px 8px 0px 8px;
}

#map {
    width: 100%;
    max-width: 640px;
    height: auto;
}

.header-img {
    max-width: 100%;
    width: auto;
    height: auto;
}

.card-container {

}

.flip-card {   
    background-color: transparent;
    width: 240px;
    height: 240px;
    perspective: 1000px;
}

.flip-card-inner {
    position: relative;
    width: 90%;
    height: 90%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;

}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    overflow: hidden;
    border: 4px solid black;
    border-radius: 10%;
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: rgba(0,0,0,0.8) 0 0 10px;
}

.flip-card-front {
    background-color: #bbb;
    color: black;
}

.flip-card-back {
    background-color: #ad2d49;
    color: white;
    transform: rotateY(180deg);
}

.container {
    position: relative;
    text-align: center;
    color: white;
}

.centered {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translate(-50%);
}

.actually-centered {
    position: absolute;
    bottom: 40%;
    left: 50%;
    transform: translate(-50%,50%);
}

.bubbletext {
    color: white;
    text-shadow: 0 0 2px #000000,
        0 0 4px #000000,
        0 0 8px #000000;
}

.img1 {
    background-image: url("https://pbs.twimg.com/profile_images/1468712187636207619/pBONCcBf_400x400.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
}

.img2 {
    background-image: url("https://pbs.twimg.com/profile_images/1507236171331489794/atyqLEqI_400x400.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
}

.img3 {
    background-image: url("https://pbs.twimg.com/profile_images/1506553273054023684/LOycYNwx_400x400.png");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
}